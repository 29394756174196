































































import Vue from 'vue';
import AdminUserService from '@/services/admin/AdminUserService';
import formatDistance from 'date-fns/formatDistance';
import { de } from 'date-fns/locale';
import { MetaInfo } from 'vue-meta';
import ToastMessage from '@/models/ToastMessage';

class AdminCustomerDetailDto {
  id!: string;
  createdAt!: Date;
  lastLoginAt!: Date;
  displayName!: string;
}

export default Vue.extend({
  props: {
    customerUserId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      ready: false,
      customer: {} as AdminCustomerDetailDto,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminGuestDetail.meta.title', { name: this.customer.displayName }).toString(),
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    isProduction() {
      return process.env.VUE_APP_SHOW_NON_PRODUCTION_BANNER == 'false';
    },
  },
  methods: {
    formatDistance(date: Date): string {
      return formatDistance(date, new Date(), { addSuffix: true, locale: de });
    },

    setBreadCrumb(): void {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'AdminCustomerIndex' }, translationKey: 'pages.admin.AdminGuestDetail.breadcrumb.guests' },
        { text: this.customer.displayName },
      ]);
    },

    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    loadData() {
      AdminUserService.getCustomer(this.customerUserId).then((resp) => {
        this.customer = resp.value;
        this.customer.createdAt = new Date(resp.value.createdAt);
        this.customer.lastLoginAt = new Date(resp.value.lastLoginAt);
        this.ready = true;
        this.setBreadCrumb();
      });
    },

    migrateToGutshof() {
      AdminUserService.convertToGutshof(this.customerUserId).then(() => {
        this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich migriert', 'bg-success'));
      });
    },

    migrateOrdersToGutshof() {
      AdminUserService.convertOrdersToGutshof(this.customerUserId).then(() => {
        this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich migriert', 'bg-success'));
      });
    },
  },
});
